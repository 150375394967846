*,
p {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  background-color: #f7f4f1;
  width: 100vw;
  height: 100vh;
}

.myIconContentBox {
  border: 2px solid red;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  margin: auto;
  transform: translate(-2px, -4px);
  position: relative;
}

.myIconContentBox.active .myIconContentPopover {
  display: block !important;
}

.myIconContentBox.active {
  border-color: black;
}

.myIconContentBox.edit {
  border-color: #56db40;
}

.myIconContentPopover {
  width: 100%;
  max-width: 300px;
  min-width: 150px;
  position: absolute;
  bottom: 23px;
  left: -65px;
  /* right: 0px; */
  background-color: #182335;
  padding: 5px;
  border-radius: 5px;
  color: white;
  display: none;
  margin: auto;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.antdRow {
  cursor: pointer;
  background-color: #FF6633;
}

.antdRow:hover {
  background-color: #FF6633;
  color: #fff;
}

/* Ant design custom style */
.ant-form-item {
  margin: 10px;
}

