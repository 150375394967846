.container-test {
    display: block;
    width: 100%;
    border: 0;
    padding: 6px;
    position: relative;
    outline: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    resize: none;
    margin-bottom: 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-left: 0;
}

.container-test:focus {
    border: 2px solid red;
    background-color: red;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 32px;
    padding: 0 0;
    
}
